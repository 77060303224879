<template>
<div class="container-fluid">
    <div class="container-fluid">
        <h2 style="text-align: center">{{ title }}</h2>
    </div>
    <div class="container formular" v-if="!cerereTrimisa">
        <el-form ref="form-line" label-position="top" :model="form" label-width="100%" :rules="rules" >
        <el-row>
            <el-form-item label="Cod Angajat" prop="CodAngajat">
                <el-col :span="24">
                    <el-input v-model="form.CodAngajat" @change="validateTag"></el-input>
                </el-col>
            </el-form-item>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-form-item label="Data Inceput Concediu" prop="DataConcediu">
                    <el-date-picker
                        v-model="form.DataConcediu[0]"
                        name="incasare_data_scadenta"
                        type="date"
                        class="full-width"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        placeholder="Selecteaza data"
                        @change="calculeazaNumarZile">
                    </el-date-picker>           
                </el-form-item>
            </el-col>  
            <el-col :span="12">
                <el-form-item label="Data Sfarsit Concediu" prop="DataConcediu">
            
                    <el-date-picker
                        v-model="form.DataConcediu[1]"
                        type="date"
                        class="full-width"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        placeholder="Selecteaza data"
                        @change="calculeazaNumarZile">
                    </el-date-picker>            
                </el-form-item>
            </el-col>  
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-form-item label="Numar zile concediu">
                    <el-input disabled v-model="NumarZileConcediu"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Data completare">
                    <el-date-picker
                        class="full-width"
                        v-model="DataCompletare"
                        type="datetime"
                        disabled
                        placeholder="Select date and time">
                    </el-date-picker>
                </el-form-item>
            </el-col>
            <el-col>
                <el-form-item label='Semnatura' >
                    <VueSignaturePad style="background-color: white; border-radius: 4px; border: 1px solid #DCDFE6; margin-bottom: 18px;" width="320px" height="160px" :options="{ penColor: 'rgb(58,132,210)'}" class="border-signature" ref="signaturePad" />
                    <el-button type="warning" plain @click="undo" >Corecteaza semnatura</el-button>            
                </el-form-item>
            </el-col>     
        </el-row>
        <el-form-item>
            <el-button type="primary" @click="sendForm">Trimite</el-button>
        </el-form-item>
    </el-form>
    </div>
    <div v-else>
        <h2 style="color: green; font-weight: bold; text-align: center; margin: 50px; margin-top: 150px;">Cererea a fost trimisa cu succes!</h2>
    </div>
</div>
</template>

<script>
import settings from "../backend/LocalSettings";
// import BasePage from "../pages/BasePage";

export default {
    name: 'Formular',
    data(){
        let ValidateTag = async (rule, value, callback) => {
            let Promise = await this.validateTag(value)

            if (!Promise) {
                callback(new Error('Angajatul cu acest Cod nu exista!'));
            } else {
                callback();
            }
        };
        return {
            title: 'Cerere concediu',
            form: { DataConcediu: [], CodAngajat: -1},
            DataCompletare: Date.now(),
            NumarZileConcediu: 0,
            cerereTrimisa: false,
            rules: {
                CodAngajat: [ { validator: ValidateTag, trigger: 'blur'} ],
                DataConcediu: [ { required: true, message: "Perioada concediului este obligatorie!" } ],
            },
            Tag: -1,
            IdFirma: -1,
            Gasit: false
        }
    },
    methods: {
        
        async calculeazaNumarZile(){
            let dataInceput = new Date(this.form.DataConcediu[0])
            let dataSfarsit = new Date(this.form.DataConcediu[1])

            const response = await this.$http.post('zile_libere/getZileLibereIntreDate', {start: this.form.DataConcediu[0], end: this.form.DataConcediu[1]});
    
            let numOfDates = this.getBusinessDatesCount(dataInceput,dataSfarsit) - response.body;

            if(dataInceput > dataSfarsit){
                this.NumarZileConcediu = "Data Incorecta!";
            }else {
                this.NumarZileConcediu = numOfDates
            }
        },
        undo() {
            this.$refs.signaturePad.undoSignature()
        },
        getBusinessDatesCount(startDate, endDate) {
            let count = 0;
            const curDate = new Date(startDate.getTime());
            while (curDate <= endDate) {
                const dayOfWeek = curDate.getDay();
                if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
                curDate.setDate(curDate.getDate() + 1);
            }
            return count;
        },
        async sendForm(){
            if(this.form){
                let dataInceput = new Date(this.form.DataConcediu[0]);
                let dataSfarsit = new Date(this.form.DataConcediu[1]);
                const { isEmpty, data }         = this.$refs.signaturePad.saveSignature()                

                this.form.NumarZileConcediu = this.NumarZileConcediu;

                this.form.DataConcediu[0] = dataInceput.getFullYear() + '-' + (dataInceput.getMonth() + 1) + '-' + dataInceput.getDate();
                this.form.DataConcediu[1] = dataSfarsit.getFullYear() + '-' + (dataSfarsit.getMonth() + 1) + '-' + dataSfarsit.getDate();
                if(typeof this.NumarZileConcediu == 'string'){
                    this.$message({
                        message: 'Data introdusa nu este corecta!',
                        type: 'warning'
                    }); 
                    return;
                };

                if(isEmpty){
                    this.$message({
                        message: 'Cererea nu este semnata!',
                        type: 'warning'
                    }); 
                    return;
                };

                let selectedObject = this.form;
                var response = await this.$http.post('formular/send', {object: selectedObject, idFirma: this.IdFirma, tag: this.Tag, semnatura: data});

                if(response.body.Ok == true){
                    this.cerereTrimisa = true;  
                    this.form = {}
                    this.NumarZileConcediu = 0
                }else {
                    this.$message({
                        message: 'A aparut o eroare!',
                        type: 'warning'
                    }); 
                }
            }else {
                console.log(this.form)
                this.$message({
                    message: 'A aparut o eroare!',
                    type: 'warning'
                }); 
            }
            
        },
        async validateTag(tag){
            const response = await this.$http.post('formular/tagValidator', {tag: tag, idFirma: this.IdFirma})
            this.Gasit = response.body.Gasit;

            return this.Gasit
        }
    },
    async mounted(){
        this.IdFirma = this.$route.params.id;
        this.form.CodAngajat = this.$route.params.tag;
        let ok = await this.validateTag(this.$route.params.tag);
        if(ok == false){
            this.$message({
                message: 'Angajatul cu acest cod nu exista!',
                type: 'warning'
            });
        }
    }
}
</script>

<style scoped>
.formular {
    padding: 25px;
}
</style>